
export enum RouteStatusCd {
	CANCELLED = 'Cancelled',
	CLOSED = 'Closed',
	COMPLETE = 'Complete',
	DISPATCHED = 'Dispatched',
	DOCK = 'Dock',
	DROPPED = 'Dropped',
	LOADING = 'Loading',
	NEW = 'New',
	RELEASED = 'Released',
	RETURNING = 'Returning',
	UNRELEASED = 'Unreleased'}
