
export enum CisRequestStatusCd {
	CANCELLED = 'Cancelled',
	COMPLETED = 'Completed',
	DUMMY = 'Dummy',
	EXPIRED = 'Expired',
	HOLD = 'Hold',
	NEW = 'New',
	OPEN = 'Open',
	REJECTED = 'Rejected',
	SUBMITTED = 'Submitted'}
