
export enum ExemptReasonCd {
	ACCIDENT = 'Accident',
	CUSTOMS = 'Customs',
	DEFERRED = 'Deferred',
	NOT_EXEMPT = 'NotExempt',
	OTHER = 'Other',
	PREFER_VOL_SHPMT = 'PreferVolShpmt',
	ROAD_CLOSURE = 'RoadClosure',
	TORNADO = 'Tornado',
	WEATHER = 'Weather'}
