
export enum OptimizerStatusCd {
	ABORTED = 'Aborted',
	CANCELLED = 'Cancelled',
	COMPLETED = 'Completed',
	FAILED = 'Failed',
	IN_PROGRESS = 'InProgress',
	NEW = 'New',
	ROUTES_COMPLETED = 'RoutesCompleted',
	ROUTES_FAILED = 'RoutesFailed',
	TIME_OUT = 'TimeOut'}
