
export enum DisputeRequestReasonCd {
	DRPEDI_NON_REVENUE_ADJUSTMENT = 'DRPEDINonRevenueAdjustment',
	DRPEDI_TRANSMISSION_ERROR = 'DRPEDITransmissionError',
	DRPFSC_DISPUTE = 'DRPFSCDispute',
	DRPOTW_ADJUSTMENT = 'DRPOTWAdjustment',
	DRP_ACCESSORIAL_DISPUTE = 'DRPAccessorialDispute',
	DRP_CARGO_LOSS_DAMAGE_DISPUTE = 'DRPCargoLossDamageDispute',
	DRP_CLAIM_DISPUTE = 'DRPClaimDispute',
	DRP_CLASS_CHANGE = 'DRPClassChange',
	DRP_CUSTOMER_ERROR = 'DRPCustomerError',
	DRP_CUSTOMER_NON_COMPLIANCE_NON_REVENUE_ADJUSTMENT = 'DRPCustomerNonComplianceNonRevenueAdjustment',
	DRP_CUSTOMER_SATISFACTION = 'DRPCustomerSatisfaction',
	DRP_EMPLOYEE_ERROR = 'DRPEmployeeError',
	DRP_FRAUD = 'DRPFraud',
	DRP_GUARANTEED_ADJUSTMENT = 'DRPGuaranteedAdjustment',
	DRP_INSPECTION_ADJUSTMENT = 'DRPInspectionAdjustment',
	DRP_INVOICE_DELIVERED_PAST_STATUTE = 'DRPInvoiceDeliveredPastStatute',
	DRP_LATE_DELIVERY = 'DRPLateDelivery',
	DRP_LINEAL_FOOT_DISPUTE = 'DRPLinealFootDispute',
	DRP_OTHER_DISPUTE = 'DRPOtherDispute',
	DRP_RATE_DISPUTE = 'DRPRateDispute',
	DRP_RATE_QUOTE_ERROR = 'DRPRateQuoteError',
	DRP_REWEIGH_ADJUSTMENT = 'DRPReweighAdjustment',
	DRP_SERVICE_RECOVERY = 'DRPServiceRecovery',
	DRP_SETTLEMENT = 'DRPSettlement',
	DRP_SYSTEM_ERROR = 'DRPSystemError',
	MRRAPRC_DECLINED = 'MRRAPRCDeclined',
	MRRA_BACKDATING_DUE_TO_RULESET_BUILDER_BANDWIDTH = 'MRRABackdatingDueToRulesetBuilderBandwidth',
	MRRA_BACKDATING_FOR_ADD_LOCATION = 'MRRABackdatingForAddLocation',
	MRRA_BACKDATING_FOR_DOCS_RECEIVED_LATE_EXTERNALISSUE = 'MRRABackdatingForDocsReceivedLateExternalissue',
	MRRA_BACKDATING_FOR_DOCS_RECEIVED_LATE_INTERNALISSUE = 'MRRABackdatingForDocsReceivedLateInternalissue',
	MRRA_BACKDATING_FOR_NEW_OPPORTUNITY = 'MRRABackdatingForNewOpportunity',
	MRRA_BACKDATING_FOR_PRICING_INCREASE = 'MRRABackdatingForPricingIncrease',
	MRRA_DELAY_IMPLEMENTING_DUE_DOC_OR_RATE_INTERPRETATION = 'MRRADelayImplementingDueDocOrRateInterpretation',
	MRRA_GOOD_WILL_DRP = 'MRRAGoodWillDRP',
	MRRA_HIERARCHY_CHANGED_DURING_NEGOTIATIONS = 'MRRAHierarchyChangedDuringNegotiations',
	MRRA_RATES_SUSPENDED_AND_NOT_REINSTATED_DUE_TO_ERROR = 'MRRARatesSuspendedAndNotReinstatedDueToError',
	MRRA_RULESET_ERROR = 'MRRARulesetError',
	OCCWI_APPEAL_NOT_APPROVED = 'OCCWIAppealNotApproved',
	OCC_ACCESSORIAL_CHARGE = 'OCCAccessorialCharge',
	OCC_APPROVAL_OF_AMENDED_CLAIM = 'OCCApprovalOfAmendedClaim',
	OCC_BILLED_IN_ERROR = 'OCCBilledInError',
	OCC_CLASS_ERROR = 'OCCClassError',
	OCC_CODE_MATCHING = 'OCCCodeMatching',
	OCC_CONTRACTUAL_EXCEPTIONS = 'OCCContractualExceptions',
	OCC_DECLINED_PER_OPEN_INVOICE = 'OCCDeclinedPerOpenInvoice',
	OCC_DUPLICATE_PAYMENT = 'OCCDuplicatePayment',
	OCC_INSPECTION = 'OCCInspection',
	OCC_INSUFFICIENT_SUPPORTING_INFORMATION = 'OCCInsufficientSupportingInformation',
	OCC_INVALID_PRO_NUMBER_OR_NOTAN_XPO_INVOICE = 'OCCInvalidProNumberOrNotanXPOInvoice',
	OCC_LINEAL_FOOT = 'OCCLinealFoot',
	OCC_MISCELLANEOUS = 'OCCMiscellaneous',
	OCC_NO_DUPLICATE_PAYMENT = 'OCCNoDuplicatePayment',
	OCC_OVERPAYMENT = 'OCCOverpayment',
	OCC_PREVIOUSLY_REFUNDED = 'OCCPreviouslyRefunded',
	OCC_RATING_ERROR = 'OCCRatingError',
	OCC_REBUTTAL_OF_CLAIM = 'OCCRebuttalOfClaim',
	OCC_REFUND_OF_CREDIT_BALANCE = 'OCCRefundOfCreditBalance',
	OCC_REWEIGH = 'OCCReweigh',
	OCC_RULESET_ERROR = 'OCCRulesetError',
	OCC_STATUTEOF_LIMITATION = 'OCCStatuteofLimitation',
	OCC_TARIFF_OR_CONTRACT = 'OCCTariffOrContract',
	OCC_WEIGHT_ERROR = 'OCCWeightError',
	WNI_INSPECTOR_ERROR = 'WNIInspectorError',
	WNI_OVERCHARGE_CLAIM = 'WNIOverchargeClaim',
	WNI_RATING_ERROR = 'WNIRatingError'}
