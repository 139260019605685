
export enum EventTypeCd {
	ADD_SHIPMENT_TO_PICKUP = 'Add Shipment to Pickup',
	CANCEL_PICKUP = 'Cancel Pickup',
	COMPLETE_PICKUP = 'Complete Pickup',
	CREATE_SCHEDULE_PICKUP = 'Create/Schedule Pickup',
	DELETE_SHIPMENT_FROM_PICKUP = 'Delete Shipment from Pickup',
	DIMENSION_UPDATE = 'Dimension Update',
	DISPATCH_PICKUP = 'Dispatch Pickup',
	HAZMAT_UPDATE = 'Hazmat Update',
	RESCHEDULE_PICKUP = 'Reschedule Pickup',
	STRAND_PICKUP = 'Strand Pickup',
	UPDATE_SHIPMENT_ON_PICKUP = 'Update Shipment on Pickup'}
