
export enum ShipmentPnDStatusCd {
	ASSIGNED = 'Assigned',
	COMPLETE = 'Complete',
	DISPATCHED = 'Dispatched',
	EXCEPTION = 'Exception',
	LOADED = 'Loaded',
	NOT_APPLICABLE = 'NotApplicable',
	RETURNING = 'Returning',
	UNASSIGNED = 'Unassigned'}
