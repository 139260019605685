
export enum LicenseEquipmentBodyCd {
	BOMM_DOLLY = 'BommDolly',
	CONTAINER = 'Container',
	CONVERTER_DOLLY = 'ConverterDolly',
	CRANE = 'Crane',
	DROM_BOX = 'DromBox',
	DRY_VAN = 'DryVan',
	DUMP_TRUCK = 'DumpTruck',
	FLAT_BED = 'FlatBed',
	FULL_TRAILER = 'FullTrailer',
	GRADER = 'Grader',
	HOPPER = 'Hopper',
	JEEP_AXLE = 'JeepAxle',
	LIFT_TRUCK = 'LiftTruck',
	LOW_BED = 'LowBed',
	OPEN_TOP_VAN = 'OpenTopVan',
	PANEL = 'Panel',
	PASSENGER_CAR = 'PassengerCar',
	PICKUP = 'Pickup',
	RADIO = 'Radio',
	REEFER_UNIT = 'ReeferUnit',
	REFRIDGERATION_VAN = 'RefridgerationVan',
	SEDAN = 'Sedan',
	SEMI_TRAILER = 'SemiTrailer',
	SHOP_MULE = 'ShopMule',
	SNOW_PLOW = 'SnowPlow',
	TANK = 'Tank',
	TRACTOR = 'Tractor',
	TRUCK = 'Truck',
	TRUCK_TRACTOR = 'TruckTractor',
	YARD_SWEEPER = 'YardSweeper'}
