
export enum CommodityClassCd {
	CLSS_100 = 'Clss100',
	CLSS_110 = 'Clss110',
	CLSS_125 = 'Clss125',
	CLSS_150 = 'Clss150',
	CLSS_175 = 'Clss175',
	CLSS_200 = 'Clss200',
	CLSS_250 = 'Clss250',
	CLSS_300 = 'Clss300',
	CLSS_350 = 'Clss350',
	CLSS_400 = 'Clss400',
	CLSS_50 = 'Clss50',
	CLSS_500 = 'Clss500',
	CLSS_55 = 'Clss55',
	CLSS_60 = 'Clss60',
	CLSS_65 = 'Clss65',
	CLSS_70 = 'Clss70',
	CLSS_775 = 'Clss775',
	CLSS_85 = 'Clss85',
	CLSS_925 = 'Clss925'}
