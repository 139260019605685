
export enum ClaimEventTypeCd {
	CLAIMANT_ADDED = 'ClaimantAdded',
	CLAIMANT_CHANGED = 'ClaimantChanged',
	CLAIM_APPROVAL_PENDING_CEO = 'ClaimApprovalPendingCeo',
	CLAIM_APPROVAL_PENDING_CLAIMS_MANAGER = 'ClaimApprovalPendingClaimsManager',
	CLAIM_APPROVAL_PENDING_DIRECTOR_OF_CLAIMS = 'ClaimApprovalPendingDirectorOfClaims',
	CLAIM_APPROVAL_PENDING_EXAMINER = 'ClaimApprovalPendingExaminer',
	CLAIM_APPROVAL_PENDING_PRESIDENT = 'ClaimApprovalPendingPresident',
	CLAIM_APPROVAL_PENDING_RVP = 'ClaimApprovalPendingRvp',
	CLAIM_APPROVAL_PENDING_RVP_SALES = 'ClaimApprovalPendingRvpSales',
	CLAIM_APPROVAL_PENDING_SVP_OPS = 'ClaimApprovalPendingSvpOps',
	CLAIM_APPROVAL_PENDING_SVP_SALES = 'ClaimApprovalPendingSvpSales',
	CLAIM_APPROVED = 'ClaimApproved',
	CLAIM_CANCELLED_BY_CUSTOMER = 'ClaimCancelledByCustomer',
	CLAIM_DECLINED = 'ClaimDeclined',
	CLAIM_DELETED_BY_CUSTOMER = 'ClaimDeletedByCustomer',
	CLAIM_FIELDS_UPDATED_ONLY = 'ClaimFieldsUpdatedOnly',
	CLAIM_REASSIGNED = 'ClaimReassigned',
	CLAIM_SAVED_AS_DRAFT = 'ClaimSavedAsDraft',
	CLAIM_SUBMITTED = 'ClaimSubmitted',
	CLAIM_UNDER_REVIEW = 'ClaimUnderReview',
	CLAIM_VIEWED = 'ClaimViewed',
	CONTACT_CHANGED = 'ContactChanged',
	DMS_DOCUMENT_ADDED = 'DMSDocumentAdded',
	INCOMING_EMAIL = 'IncomingEmail',
	NOTE_ADDED = 'NoteAdded',
	PAYEE_ADDED = 'PayeeAdded',
	PAYEE_CHANGED = 'PayeeChanged',
	PAYMENT_CHECK_CANCELLED = 'PaymentCheckCancelled',
	PAYMENT_CHECK_CASHED = 'PaymentCheckCashed',
	PAYMENT_CHECK_ISSUED = 'PaymentCheckIssued',
	PAYMENT_DECLINED = 'PaymentDeclined',
	PAYMENT_ON_HOLD = 'PaymentOnHold',
	PAYMENT_PENDING_TRANSMISSIONTO_AP = 'PaymentPendingTransmissiontoAP',
	PAYMENT_SENT_TO_AP = 'PaymentSentToAP',
	PAYMENT_VOIDED = 'PaymentVoided',
	REBUTTAL_APPROVAL_PENDING_CEO = 'RebuttalApprovalPendingCeo',
	REBUTTAL_APPROVAL_PENDING_CLAIMS_MANAGER = 'RebuttalApprovalPendingClaimsManager',
	REBUTTAL_APPROVAL_PENDING_DIRECTOR_OF_CLAIMS = 'RebuttalApprovalPendingDirectorOfClaims',
	REBUTTAL_APPROVAL_PENDING_EXAMINER = 'RebuttalApprovalPendingExaminer',
	REBUTTAL_APPROVAL_PENDING_PRESIDENT = 'RebuttalApprovalPendingPresident',
	REBUTTAL_APPROVAL_PENDING_RVP = 'RebuttalApprovalPendingRVP',
	REBUTTAL_APPROVAL_PENDING_RVP_SALES = 'RebuttalApprovalPendingRvpSales',
	REBUTTAL_APPROVAL_PENDING_SVP_OPS = 'RebuttalApprovalPendingSvpOps',
	REBUTTAL_APPROVAL_PENDING_SVP_SALES = 'RebuttalApprovalPendingSvpSales',
	REBUTTAL_APPROVED = 'RebuttalApproved',
	REBUTTAL_CANCELLED_BY_CUSTOMER = 'RebuttalCancelledByCustomer',
	REBUTTAL_DECLINED = 'RebuttalDeclined',
	REBUTTAL_RECEIVED = 'RebuttalReceived',
	REBUTTAL_UNDER_REVIEW = 'RebuttalUnderReview',
	SUBROGATION_PAYMENT_APPROVED = 'SubrogationPaymentApproved',
	SUBROGATION_PAYMENT_DECLINED = 'SubrogationPaymentDeclined',
	SUBROGATION_PAYMENT_RECEIVED = 'SubrogationPaymentReceived',
	SUBROGATION_SUBMITTED_TO_CARRIER = 'SubrogationSubmittedToCarrier'}
