
export enum ClaimChargeabilityReasonCd {
	BOL_NOT_SIGNED_CORRECTLY = 'BOLNotSignedCorrectly',
	CONSIGNEE_UNLOAD_WITHOUT_DOCS = 'ConsigneeUnloadWithoutDocs',
	DEFINITE_EVIDENCE_OF_DAMAGE_OR_LOSS = 'DefiniteEvidenceOfDamageOrLoss',
	FREIGHT_DISPOSED_WITHOUT_PROCESS = 'FreightDisposedWithoutProcess',
	INSPECTION_NOT_PERFORMED = 'InspectionNotPerformed',
	INTERNAL_HISTORY_SHARED = 'InternalHistoryShared',
	NO_DMG_DXFO_PHOTOS = 'NoDmgDXFOPhotos',
	NO_DR_IN_IMAGE = 'NoDRInImage',
	SLC_NOT_FOLLOWED = 'SLCNotFollowed',
	VAGUE_EXCEPTION_ON_DR = 'VagueExceptionOnDR'}
