
export enum EventLogSubTypeCd {
	ALL_SHORT_DLVY = 'AllShortDlvy',
	APPOINTMENT_DLVY = 'AppointmentDlvy',
	ARCHIVE_NORMAL_PURGE = 'ArchiveNormalPurge',
	ATTEMPTED_DLVY = 'AttemptedDlvy',
	AUTO_RATED = 'AutoRated',
	BOL_IMAGED = 'BOLImaged',
	BOL_SKEL_4_SUP_REF = 'BOLSkel4SupRef',
	BORDER_CROSS_CN_TO_US = 'BorderCrossCNToUS',
	BORDER_CROSS_MX_TO_US = 'BorderCrossMXToUS',
	BORDER_CROSS_US_TO_CN = 'BorderCrossUSToCN',
	BORDER_CROSS_US_TO_MX = 'BorderCrossUSToMX',
	CARTAGE_DLVY = 'CartageDlvy',
	CCOR_UPDATE = 'CCORUpdate',
	CHILD_PRO_DLVY = 'ChildProDlvy',
	COMPRESS_LOCX_DLVY = 'CompressLOCXDlvy',
	CORRECT_MOVR_MOTHER = 'CorrectMOVRMother',
	CUBE_UPDATE = 'CubeUpdate',
	CUSTOMER_MATCH = 'CustomerMatch',
	CUSTOMER_MATCH_DIRECT_CODE = 'CustomerMatchDirectCode',
	CUSTOMS_CSA_ALTERED_CN = 'CustomsCSAAlteredCN',
	CUSTOMS_CSA_ALTERED_MX = 'CustomsCSAAlteredMX',
	CUSTOMS_CSA_ALTERED_US = 'CustomsCSAAlteredUS',
	CUSTOMS_HOLD_DLVY = 'CustomsHoldDlvy',
	DAMAGED_DLVY = 'DamagedDlvy',
	DELIVERY_RECEIPT_IMAGED = 'DeliveryReceiptImaged',
	DELIVERY_RECEIPT_PRINTED = 'DeliveryReceiptPrinted',
	EDIBOL_CREATE = 'EDIBOLCreate',
	FINAL_DLVY = 'FinalDlvy',
	FORCE_SHIP_PURGE = 'ForceShipPurge',
	LINEHAUL_ARRIVAL = 'LinehaulArrival',
	LINEHAUL_DELAY = 'LinehaulDelay',
	LINEHAUL_DISPATCH = 'LinehaulDispatch',
	LOADING_SKEL_PRO = 'LoadingSkelPro',
	LOAD_BY_PRO = 'LoadByPro',
	LOAD_BY_TRAILER = 'LoadByTrailer',
	NON_TCON_UNLOAD = 'NonTCONUnload',
	OVER_DLVY = 'OverDlvy',
	PART_SHORT_DLVY = 'PartShortDlvy',
	PCO_PURGE = 'PCOPurge',
	PICKUP_REQUEST = 'PickupRequest',
	PORT_CD_ALTERED_CN = 'PortCdAlteredCN',
	PORT_CD_ALTERED_MX = 'PortCdAlteredMX',
	PORT_CD_ALTERED_US = 'PortCdAlteredUS',
	PURGE_BLOCK_DLVY = 'PurgeBlockDlvy',
	RATED_BILL = 'RatedBill',
	RATE_AUDITOR_RATED = 'RateAuditorRated',
	RATE_AUDITOR_UNRATED = 'RateAuditorUnrated',
	REFUSED_DLVY = 'RefusedDlvy',
	RETURN_DLVY = 'ReturnDlvy',
	REV_BILL_ENTRY = 'RevBillEntry',
	SENT_2_RATE_AUDIT = 'Sent2RateAudit',
	SHIPMENT_CORRECTION = 'ShipmentCorrection',
	SHIP_PURGE_PRO_AVAIL = 'ShipPurgeProAvail',
	SHIP_RESET_4_BILL_ENTRY = 'ShipReset4BillEntry',
	SPOTTED_DLVY = 'SpottedDlvy',
	SUSPENSE_DLVY = 'SuspenseDlvy',
	TRANSFER_DLVY = 'TransferDlvy',
	TRAPPED_DLVY = 'TrappedDlvy',
	UNDELIVERED_DLVY = 'UndeliveredDlvy',
	UNLOAD_ASSUMPTIVE_STRIP = 'UnloadAssumptiveStrip',
	UNLOAD_BY_PRO = 'UnloadByPro',
	UNLOAD_BY_TRAILER = 'UnloadByTrailer',
	UNLOAD_FORCE_STRIP = 'UnloadForceStrip',
	UPDATE_DLVY = 'UpdateDlvy',
	WAIT_DLVY = 'WaitDlvy',
	WEB_BOL_CREATE = 'WebBOLCreate'}
