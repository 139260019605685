
export enum InvoiceAccuracyConstraintCd {
	ACCESSORIAL_CD = 'AccessorialCd',
	BILL_2_CUST_NUMBER = 'Bill2CustNumber',
	BILL_TO_RELATED_CODE_1 = 'BillToRelatedCode1',
	BILL_TO_RELATED_CODE_2 = 'BillToRelatedCode2',
	CONSIGNEE_CUST_NUMBER = 'ConsigneeCustNumber',
	COUPON_CD = 'CouponCd',
	CUST_TYPE_CODE = 'CustTypeCode',
	PRICING_AGREEMENT_ID = 'PricingAgreementId',
	RATING_TARIFF_NAME = 'RatingTariffName',
	SHIPPER_CUST_NUMBER = 'ShipperCustNumber',
	TOTAL_CHARGE_AMOUNT = 'TotalChargeAmount',
	TOTAL_PALLET_CNT = 'TotalPalletCnt',
	TRUE_DEBTOR_CUSTOMER_NUMBER = 'TrueDebtorCustomerNumber'}
