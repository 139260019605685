
export enum DisputeDecisionReasonSubTypeCd {
	ACCESSORIAL_CHARGE = 'AccessorialCharge',
	ACCESSORIAL_DISPUTE = 'AccessorialDispute',
	AMENDED_CLAIM = 'AmendedClaim',
	APPROVED_PROCESSED_SUBMITTED_IN_ERROR = 'ApprovedProcessedSubmittedInError',
	AUDIT_COMPANY_REQUESTED_CLAIM_CANCELLATION = 'AuditCompanyRequestedClaimCancellation',
	AUTO_DECLINED = 'AutoDeclined',
	BACKDATING_DUE_TO_RULESET_BUILDER_BANDWIDTH = 'BackdatingDueToRulesetBuilderBandwidth',
	BACKDATING_FOR_ADD_LOCATION = 'BackdatingForAddLocation',
	BACKDATING_FOR_DOCS_RECEIVED_LATE_EXTERNAL_ISSUE = 'BackdatingForDocsReceivedLateExternalIssue',
	BACKDATING_FOR_DOCS_RECEIVED_LATE_INTERNAL_ISSUE = 'BackdatingForDocsReceivedLateInternalIssue',
	BACKDATING_FOR_NEW_OPPORTUNITY = 'BackdatingForNewOpportunity',
	BACKDATING_FOR_PRICING_INCREASE = 'BackdatingForPricingIncrease',
	BAD_DEBT_WRITE_OFF = 'BadDebtWriteOff',
	BILLING_ERROR = 'BillingError',
	CARGO_LOSS_DAMAGE_DISPUTE = 'CargoLossDamageDispute',
	CLASS_CHANGE = 'ClassChange',
	CLASS_CORRECTLY_BILLED = 'ClassCorrectlyBilled',
	CODE_MATCHING = 'CodeMatching',
	CONTRACTUAL_EXCEPTIONS = 'ContractualExceptions',
	CORRECTED_VIA_ANOTHER_PROCESS = 'CorrectedViaAnotherProcess',
	CUSTOMER_ERROR = 'CustomerError',
	CUSTOMER_SATISFACTION = 'CustomerSatisfaction',
	DELAY_DUE_TO_DOCUMENT_RATE_INTERPRETATION = 'DelayDueToDocumentRateInterpretation',
	DRP_DECLINED = 'DRPDeclined',
	DUPLICATE_CLAIM = 'DuplicateClaim',
	DUPLICATE_CLAIM_PRO_NUMBER = 'DuplicateClaimProNumber',
	DUPLICATE_PAYMENT = 'DuplicatePayment',
	EMPLOYEE_ERROR = 'EmployeeError',
	EMPLOYEE_NO_LONGER_WITH_XPO = 'EmployeeNoLongerWithXPO',
	GUARANTEED = 'Guaranteed',
	HIERARCHY_CHANGED_CAUSING_STRANDED_PRICING = 'HierarchyChangedCausingStrandedPricing',
	INCORRECT_ADJUSTMENT_AMOUNT = 'IncorrectAdjustmentAmount',
	INCORRECT_INVOICE_NUMBER = 'IncorrectInvoiceNumber',
	INSPECTION = 'Inspection',
	INSPECTOR_ERROR = 'InspectorError',
	INSUFFICIENT_SUPPORTING_INFORMATION = 'InsufficientSupportingInformation',
	INTERNAL_SYSTEM_ERROR = 'InternalSystemError',
	INVALID_PRO_NUMBER_NOT_A_CONWAY_INVOICE = 'InvalidProNumberNotAConwayInvoice',
	LINEAL_FOOT = 'LinealFoot',
	MISCELLANEOUS = 'Miscellaneous',
	NEED_MORE_INFORMATION = 'NeedMoreInformation',
	NON_ERROR_CORRECTION = 'NonErrorCorrection',
	NO_DUPLICATE_PAYMENT = 'NoDuplicatePayment',
	OPEN_INVOICE = 'OpenInvoice',
	OTHER = 'Other',
	POLICY_PAY_CHANGE = 'PolicyPayChange',
	PRC_DECLINED = 'PRCDeclined',
	PREVIOUSLY_REFUNDED = 'PreviouslyRefunded',
	PURSUING_COLLECTION_EFFORT = 'PursuingCollectionEffort',
	RATES_SUSPENDED_AND_NOT_REINSTATED_IN_ERROR = 'RatesSuspendedAndNotReinstatedInError',
	RATE_DISPUTE = 'RateDispute',
	RATING_ERROR = 'RatingError',
	REALLOCATE_WORKLOAD = 'ReallocateWorkload',
	REFUND_OF_CREDIT_BALANCE = 'RefundOfCreditBalance',
	REWEIGH = 'Reweigh',
	REWEIGH_ADJUSTMENT = 'ReweighAdjustment',
	RULESET_ERROR = 'RulesetError',
	RULESET_ERROR_RATE_DISC_FAK_FUEL_ACCESSORIAL = 'RulesetErrorRateDiscFAKFuelAccessorial',
	STATUTE_OF_LIMITATION = 'StatuteOfLimitation',
	SYSTEM_ISSUE = 'SystemIssue',
	TARIFF_OR_CONTRACT = 'TariffOrContract',
	TERMS_BILLED_CORRECTLY_PER_BILL_OF_LADING = 'TermsBilledCorrectlyPerBillOfLading',
	WEIGHT_CORRECTION = 'WeightCorrection',
	WI_APPEAL_NOT_APPROVED = 'WIAppealNotApproved',
	WRONG_AUDIT_COMPANY = 'WrongAuditCompany',
	WRONG_CODE_USED_WRONG_ADDRESS = 'WrongCodeUsedWrongAddress'}
