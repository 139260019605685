
export enum RadioactivityUomCd {
	CURIE = 'Curie',
	GIGABECQUEREL = 'Gigabecquerel',
	KILOBECQUEREL = 'Kilobecquerel',
	MEGABECQUEREL = 'Megabecquerel',
	MICROCURIE = 'Microcurie',
	MILLICURIE = 'Millicurie',
	NANOCURIE = 'Nanocurie',
	TERABECQUEREL = 'Terabecquerel'}
