
export enum ReturnReasonCd {
	DRIVER_RELATED = 'DriverRelated',
	INSUFFICIENT_DELIVERY_TIME = 'InsufficientDeliveryTime',
	MECHANICAL_BREAKDOWN = 'MechanicalBreakdown',
	MISSED_DELIVERY = 'MissedDelivery',
	OTHER_CARRIER = 'OtherCarrier',
	PACKAGE_MISSORTED = 'PackageMissorted',
	PREVIOUS_STOP_DELIVERY = 'PreviousStopDelivery',
	SHIPPER_RELATED = 'ShipperRelated'}
