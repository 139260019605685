
export enum FieldDisplayTypeCd {
	BUTTON = 'Button',
	CHECKBOX = 'Checkbox',
	DATE_PICKER = 'DatePicker',
	FIELD_DISPLAY_TEXT = 'FieldDisplayText',
	MULTI_LINE_TEXT_BOX = 'MultiLineTextBox',
	MULTI_SELECT_DROP_DOWN = 'MultiSelectDropDown',
	RADIO_BUTTON = 'RadioButton',
	RADIO_GROUP = 'RadioGroup',
	SINGLE_LINE_TEXT_BOX = 'SingleLineTextBox',
	SINGLE_SELECT_DROP_DOWN = 'SingleSelectDropDown'}
