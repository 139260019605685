
export enum CssClassCd {
	ASSIGN_ACTIONS = 'AssignActions',
	BACKGROUND_INFO = 'BackgroundInfo',
	BACKGROUND_INFO_MCA = 'BackgroundInfoMCA',
	COMMENTS = 'Comments',
	DISCIPLINE_RECOGNITION = 'DisciplineRecognition',
	ISSUES_QUESTIONS = 'IssuesQuestions',
	QUESTIONS = 'Questions',
	TOPICS_DISCUSSED = 'TopicsDiscussed'}
