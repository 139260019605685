
export enum DisputeEventTypeCd {
	CLAIMANT_ADDED = 'ClaimantAdded',
	CLAIMANT_CHANGED = 'ClaimantChanged',
	CLAIMANT_CONTACT_ADDED = 'ClaimantContactAdded',
	DISPUTE_AMENDED = 'DisputeAmended',
	DISPUTE_APPROVED = 'DisputeApproved',
	DISPUTE_ASSIGNED = 'DisputeAssigned',
	DISPUTE_CANCELLED = 'DisputeCancelled',
	DISPUTE_CLOSED = 'DisputeClosed',
	DISPUTE_CORRECTIONS_IN_PROGRESS = 'DisputeCorrectionsInProgress',
	DISPUTE_DECLINED = 'DisputeDeclined',
	DISPUTE_DELETED = 'DisputeDeleted',
	DISPUTE_FIELDS_UPDATED_ONLY = 'DisputeFieldsUpdatedOnly',
	DISPUTE_REASSIGNED = 'DisputeReassigned',
	DISPUTE_SAVED_AS_DRAFT = 'DisputeSavedAsDraft',
	DISPUTE_SHIPMENT_ADDED_OR_REMOVED = 'DisputeShipmentAddedOrRemoved',
	DISPUTE_SHIPMENT_SPREADSHEET_UPLOADED = 'DisputeShipmentSpreadsheetUploaded',
	DISPUTE_SUBMITTED = 'DisputeSubmitted',
	DISPUTE_SUBMITTED_FOR_REVIEW = 'DisputeSubmittedForReview',
	DISPUTE_UNASSIGNED = 'DisputeUnassigned',
	DISPUTE_UNDER_REVIEW = 'DisputeUnderReview',
	DMS_DOCUMENT_ADDED = 'DmsDocumentAdded',
	INCOMING_EMAIL = 'IncomingEmail',
	NOTE_ADDED = 'NoteAdded',
	ON_BEHALF_OF_ADDED = 'OnBehalfOfAdded',
	ON_BEHALF_OF_CHANGED = 'OnBehalfOfChanged',
	ON_BEHALF_OF_CONTACT_ADDED = 'OnBehalfOfContactAdded'}
