
export enum ClaimFieldName {
	APPROVED_AMOUNT = 'approvedAmount',
	CLAIMANT_NAME = 'claimantName',
	CLAIMANT_REFERENCE_NBR = 'claimantReferenceNbr',
	CLAIMED_AMOUNT = 'claimedAmount',
	CLAIM_ID = 'claimId',
	CLOSED_DATE_TIME = 'closedDateTime',
	CURRENT_ASSIGNEE_EMPLOYEE_ID = 'currentAssigneeEmployeeId',
	EXAMINED_BY_EMPLOYEE_ID = 'examinedByEmployeeId',
	FILING_DATE = 'filingDate',
	INTERNAL_STATUS_CD = 'internalStatusCd',
	MAX_LIABILITY_AMOUNT = 'maxLiabilityAmount',
	PAID_AMOUNT = 'paidAmount',
	PICKUP_DATE = 'pickupDate',
	PRO_NBR = 'proNbr',
	REBUTTAL_IND = 'rebuttalInd',
	RECEIVED_DATE_TIME = 'receivedDateTime',
	REVIEW_REQUIRED_DESCRIPTION = 'reviewRequiredDescription',
	UPDATES_FLAG = 'updatesFlag'}
